import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  Flex,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { useVendor } from "../../../context/VendorContext";

const VendorForm = ({ operation, isOpen, onClose, initialData = null }) => {
  const { addVendor, updateVendor } = useVendor();
  const [formData, setFormData] = useState({
    companyName: "",
    email: "",
    phone: "",
    companyAddress: {
      address:"",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
    companyDesc: "",
    user_limits: {
      bd: 1,
      recruiter: 1,
      manager: 1,
    },
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.includes("companyAddress.")) {
      const field = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        companyAddress: {
          ...prevState.companyAddress,
          [field]: value,
        },
      }));
    } else if (name.includes("user_limits.")) {
      const field = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        user_limits: {
          ...prevState.user_limits,
          [field]: Number(value),
        },
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = () => {
    if (initialData) {
      updateVendor(formData);
      console.log("Vendor Data Updated:", formData);
    } else {
      addVendor(formData);
      console.log("Vendor Data Submitted:", formData);
    }
    onClose();
  };

  return (
    <Box p={4}>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{operation}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3} isRequired>
              <FormLabel>Company Name</FormLabel>
              <Input
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Enter company name"
              />
            </FormControl>

            <Flex gap={4} mb={3}>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Phone</FormLabel>
                <Input
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                />
              </FormControl>
            </Flex>

            <FormControl mb={3}>
              <FormLabel>Address</FormLabel>
              <Input
                mb={3}
                name="companyAddress.address"
                value={formData.companyAddress.address}
                onChange={handleChange}
                placeholder="Enter address"
              />
              <Flex gap={4}>
                <Input
                  name="companyAddress.city"
                  value={formData.companyAddress.city}
                  onChange={handleChange}
                  placeholder="City"
                />
                <Input
                  name="companyAddress.state"
                  value={formData.companyAddress.state}
                  onChange={handleChange}
                  placeholder="State"
                />
              </Flex>
              <Flex gap={4} mt={4}>
                <Input
                  name="companyAddress.country"
                  value={formData.companyAddress.country}
                  onChange={handleChange}
                  placeholder="Country"
                />
                <Input
                  name="companyAddress.pincode"
                  value={formData.companyAddress.pincode}
                  onChange={handleChange}
                  placeholder="Pincode"
                />
              </Flex>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Company Description</FormLabel>
              <Textarea
                name="companyDesc"
                value={formData.companyDesc}
                onChange={handleChange}
                placeholder="Enter company description"
                rows={4}
              />
            </FormControl>

            <Text fontWeight="bold" mb={2}>
              User Limits
            </Text>
            <Flex gap={4} mb={3}>
              <FormControl>
                <FormLabel>BD</FormLabel>
                <NumberInput
                  min={1}
                  value={formData.user_limits.bd}
                  onChange={(value) =>
                    handleChange({
                      target: { name: "user_limits.bd", value },
                    })
                  }
                >
                  <NumberInputField name="user_limits.bd" />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Recruiter</FormLabel>
                <NumberInput
                  min={1}
                  value={formData.user_limits.recruiter}
                  onChange={(value) =>
                    handleChange({
                      target: { name: "user_limits.recruiter", value },
                    })
                  }
                >
                  <NumberInputField name="user_limits.recruiter" />
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Manager</FormLabel>
                <NumberInput
                  min={1}
                  value={formData.user_limits.manager}
                  onChange={(value) =>
                    handleChange({
                      target: { name: "user_limits.manager", value },
                    })
                  }
                >
                  <NumberInputField name="user_limits.manager" />
                </NumberInput>
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {operation === "Update Vendor" ? "Update" : "Submit"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default VendorForm;
