import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Flex,
  Image,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import companyLogo from "../../../assets/cLogo.png";
import dayaStams from "../../../assets/dayaStams.png";
import convertToLPA from "../../../Utils/GlobalFunctions/FormatSalary";
import { convertISOToDate } from "../../../Utils/GlobalFunctions/FormatDate";

const BillTemplates = ({ isOpen, onClose, invoiceData }) => {
  const modalRef = useRef();
  const downloadButtonRef = useRef();
  const closeButtonRef = useRef();
  const modalCloseButtonRef = useRef(); // Ref for the close button

  // Mock Invoice ID and Date
  function generateInvoiceID(invoiceData) {
    // Validate that invoiceData and jobId exist
    if (
      !invoiceData ||
      !invoiceData.application ||
      !invoiceData.application.jobDescription ||
      !invoiceData.application.jobDescription.jobId
    ) {
      throw new Error("Invalid invoice data: jobId is missing");
    }

    // Extract jobId
    const jobId = invoiceData.application.jobDescription.jobId;

    // Create a unique invoice ID with jobId and a timestamp for uniqueness
    const timestamp = Date.now();
    const invoiceID = `INV-${jobId}-${timestamp}`;

    return invoiceID;
  }

  const subTotal = (amount)=>{
    return (amount+amount*0.18).toFixed(2)
  }
  const invoiceDate = new Date().toLocaleDateString(); // Current Date
  const downloadPDF = async () => {
    if (!modalRef.current) return;

    try {
      // Temporarily hide buttons
      if (downloadButtonRef.current)
        downloadButtonRef.current.style.display = "none";
      if (closeButtonRef.current) closeButtonRef.current.style.display = "none";
      if (modalCloseButtonRef.current)
        modalCloseButtonRef.current.style.display = "none";

      // Capture the modal content as a canvas
      const canvas = await html2canvas(modalRef.current, {
        scale: 2, // Scale down for smaller size
        backgroundColor: "white", // Ensure white background
      });

      const imgData = canvas.toDataURL("image/jpeg", 0.7); // Reduce image quality to 70%

      // Set up the PDF
      const pdf = new jsPDF("portrait", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Scale image dimensions for PDF
      const imgWidth = pageWidth - 20; // Reduce width
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Adjust image height for smaller PDFs
      const maxHeight = pageHeight - 20; // Allow margin
      const finalHeight = Math.min(imgHeight, maxHeight);

      // Add image to PDF
      pdf.addImage(imgData, "JPEG", 10, 10, imgWidth, finalHeight);

      // Save the PDF
      pdf.save("invoice.pdf");

      // Restore button visibility
      if (downloadButtonRef.current)
        downloadButtonRef.current.style.display = "inline-block";
      if (closeButtonRef.current)
        closeButtonRef.current.style.display = "inline-block";
      if (modalCloseButtonRef.current)
        modalCloseButtonRef.current.style.display = "inline-block";
    } catch (error) {
      console.error("Error generating PDF:", error);

      // Restore button visibility in case of error
      if (downloadButtonRef.current)
        downloadButtonRef.current.style.display = "inline-block";
      if (closeButtonRef.current)
        closeButtonRef.current.style.display = "inline-block";
      if (modalCloseButtonRef.current)
        modalCloseButtonRef.current.style.display = "inline-block";
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        ref={modalRef}
        bg="white"
        color="black"
        sx={{ maxWidth: "60vw" }}
        style={{ fontSize: "14px", lineHeight: "1.5" }}
      >
        {" "}
        {/* Ensure white background and black text */}
        {/* Header Section */}
        <ModalHeader>
          <Flex justify="space-between" align="center">
            {/* Left Section: Logo and Address */}
            <Flex align="center">
              {/* Logo */}
              <Image
                src={companyLogo}
                alt="Company Logo"
                boxSize="200px"
                mr="4"
              />
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Daya Consultancy Services (OPC) Pvt. Ltd.
                </Text>
                <Text fontSize="sm" color="gray.600">
                  B18, Saheed Nagar, Bhubaneswar, 751007
                </Text>
                <Text fontSize="sm" color="gray.600">
                  GST: 21AACBD0898AIZT | CIN: U74140OR2021
                </Text>
              </Box>
            </Flex>

            {/* Right Section: Invoice ID and Date */}
            <Box textAlign="right">
              <Text fontSize="lg" fontWeight="bold" color="blue.600">
                INVOICE
              </Text>
              <Text fontSize="sm" color="gray.600">
                ID: {generateInvoiceID(invoiceData)}
              </Text>
              <Text fontSize="sm" color="gray.600">
                Date: {invoiceDate}
              </Text>
            </Box>
          </Flex>
        </ModalHeader>
        <ModalCloseButton ref={modalCloseButtonRef} />
        {/* Body Section */}
        <ModalBody>
          {/* Invoice Details */}
          <Flex justify="space-between" mb="6">
            <Box>
              <Text fontWeight="bold">Bill From:</Text>
              <Text>Daya Consultancy Services (OPC) Pvt. Ltd.</Text>
              <Text>B18, Saheed Nagar, Bhubaneswar, Odisha</Text>
              <Text>Phone: 7715818235</Text>
              <Text>Email: info@dayacs.com</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Bill To:</Text>
              <Text>Keva Kaipo Industries Private Limited</Text>
              <Text>Plot Number 18 & 19, Tibba Village,</Text>
              <Text>Sahnewal, Ludhiana-141120</Text>
              <Text>GSTIN: 03AAICK0668F1ZQ</Text>
            </Box>
          </Flex>

          {/* Candidate Details */}
          <Text fontWeight="bold" mb="4">
            Candidate Details
          </Text>
          <TableContainer>
            <Table variant="striped" colorScheme="green">
              <Tbody>
                <Tr>
                  <Th>Candidate/Employee Name</Th>
                  <Td color="whitesmoke">
                    {invoiceData?.application?.candidate?.name}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Date of Joining</Th>
                  <Td>
                    {convertISOToDate(
                      invoiceData?.application?.placementDetails?.joiningDate
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Employee ID</Th>
                  <Td color="whitesmoke">92</Td>
                </Tr>
                <Tr>
                  <Th>CTC of Candidate</Th>
                  <Td>{convertToLPA(invoiceData?.ctc)}</Td>
                </Tr>
                <Tr>
                  <Th>Margin</Th>
                  <Td color="whitesmoke">{invoiceData?.pshare}%</Td>
                </Tr>
                <Tr>
                  <Th>Amount</Th>
                  <Td>₹{invoiceData?.amount?.toFixed(2)}</Td>
                </Tr>
                <Tr>
                  <Th ml="40%">CGST</Th>
                  <Td color="whitesmoke">₹{(invoiceData?.amount * 0.09).toFixed(2)}</Td>
                </Tr>
                <Tr>
                  <Th>SGST</Th>
                  <Td>₹{(invoiceData?.amount * 0.09).toFixed(2)}</Td>
                </Tr>
                <Tr>
                  <Th>IGST</Th>
                  <Td color="whitesmoke">₹{(invoiceData?.amount * 0.18).toFixed(2)}</Td>
                </Tr>
                <Tr bg="#8bd005">
                  <Th ml="10" color="black">Sub Total</Th>
                  <Td fontWeight="bold">₹{subTotal(invoiceData?.amount)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          {/* Footer Section */}
          <Flex justifyContent="space-between">
            <Box mt="6">
              {/* <Text>
              <strong>Rupees in Words:</strong> Twelve Thousand Nine Hundred
              Seventy-Five
            </Text> */}
              <Text mt="4">
                <strong>Mode of Payment:</strong> HDFC Bank
              </Text>
              <Text>
                Account Holder: Daya Consultancy Services (OPC) Pvt. Ltd.
              </Text>
              <Text>Account Number: 50200063266769</Text>
              <Text>IFSC Code: HDFC0001117</Text>
              <Text>Branch: Puri</Text>
            </Box>
            <Box mt="100px">
              <Image
                src={dayaStams}
                alt="dayaStams Logo"
                // boxSize="150px"
                width="fit-content"
              />
            </Box>
          </Flex>
        </ModalBody>
        {/* Actions */}
        <Box p="4" textAlign="right">
          <Button
            ref={closeButtonRef}
            colorScheme="blue"
            mr="3"
            onClick={onClose}
            bg="red"
            color="black"
            size="sm"
            _hover={{ bg: "gray" }}
          >
            Close
          </Button>
         {
          (invoiceData?.paymentStatus === "Completed" ||  invoiceData?.paymentStatus === "Due") ? <Button
          ref={downloadButtonRef}
          colorScheme="teal"
          onClick={downloadPDF}
          bg="#8bd005"
          color="black"
          size="sm"
          _hover={{ bg: "gray" }}
        >
          Download PDF
        </Button>:<Button _disabled={true}  bg="gray"
          color="black"
          size="sm"
          _hover={{ bg: "gray" }}>!Paymemt</Button>
         }
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default BillTemplates;
