import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { EnvContextProvider } from "./context/EnvContext";
import { extendTheme } from "@chakra-ui/react";
import { UserContextProvider } from "./context/UserContext";
import { JDContextProvider } from "./context/JDContext";
import { CandidateContextProvider } from "./context/CandidateContext";
import { ApplicationContextProvider } from "./context/ApplicationContext";
import { BillingContextProvider } from "./context/BillingContext";
import { AdminContextProvider } from "./context/AdminContext";
import { DashboardContextProvider } from "./context/DashboardContext";
import { ClientContextProvider } from "./context/ClientContext";
import { VendorContextProvider } from "./context/VendorContext";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <EnvContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <VendorContextProvider>
              <ClientContextProvider>
                <JDContextProvider>
                  <CandidateContextProvider>
                    <ApplicationContextProvider>
                      <BillingContextProvider>
                        <AdminContextProvider>
                          <DashboardContextProvider>
                            <App />
                          </DashboardContextProvider>
                        </AdminContextProvider>
                      </BillingContextProvider>
                    </ApplicationContextProvider>
                  </CandidateContextProvider>
                </JDContextProvider>
              </ClientContextProvider>
            </VendorContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </EnvContextProvider>
    </ChakraProvider>
  </BrowserRouter>
);
