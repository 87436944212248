import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

const VendorDetails = ({ isOpen, onClose, vendor }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Vendor Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div style={{ marginBottom: '20px' }}>
            <p style={{ marginBottom: '8px' }}>
              <strong>Company Name:</strong> {vendor?.companyName}
            </p>
            <p style={{ marginBottom: '8px' }}>
              <strong>Email:</strong> {vendor?.email}
            </p>
            <p style={{ marginBottom: '8px' }}>
              <strong>Phone:</strong> {vendor?.phone}
            </p>
            <p style={{ marginBottom: '8px' }}>
              <strong>Address:</strong>
            </p>
            <ul style={{ marginLeft: '20px', marginBottom: '20px' }}>
              <li><strong>City:</strong> {vendor?.companyAddress?.city}</li>
              <li><strong>State:</strong> {vendor?.companyAddress?.state}</li>
              <li><strong>Country:</strong> {vendor?.companyAddress?.country}</li>
            </ul>
            <p style={{ marginBottom: '20px' }}>
              <strong>Description:</strong> {vendor?.comanyDesc}
            </p>
          </div>

          <p style={{ marginBottom: '10px' }}><strong>User Limits:</strong></p>
          <Table variant="simple" size="sm" mb={4}>
            <Thead>
              <Tr>
                <Th>Role</Th>
                <Th isNumeric>Limit</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>BD</Td>
                <Td isNumeric>{vendor?.user_limits?.bd}</Td>
              </Tr>
              <Tr>
                <Td>Recruiter</Td>
                <Td isNumeric>{vendor?.user_limits?.recruiter}</Td>
              </Tr>
              <Tr>
                <Td>Manager</Td>
                <Td isNumeric>{vendor?.user_limits?.manager}</Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VendorDetails;
