import {
  Box,
  Button,
  Flex,

} from "@chakra-ui/react";
import { useState } from "react";
import VendorForm from "./VendorForm";

const VendorHeader = () => {
  const [isVendorFormModalOpen, setIsVendorFormModalOpen] = useState(false);

  const handleVendorForm = () => {
    setIsVendorFormModalOpen(true);
  };

  const handleVendorFormCloseModal = () => {
    setIsVendorFormModalOpen(false);
  };

  return (
    <>
      <Flex
        direction={"row-reverse"}
        alignItems={"center"}
        position={"absolute"}
        top={"6px"}
        right="0"
      >
        <Button
          colorScheme="gray"
          size="xs"
          style={{
            borderRadius: "20px",
            background: "#1F2937",
            color: "white",
            marginInline: "10px",
            transition: "background 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.target.style.background = "#3B4A5A";
          }}
          onMouseLeave={(e) => {
            e.target.style.background = "#1F2937";
          }}
          onClick={handleVendorForm}
        >
          Add Vendor
        </Button>
      </Flex>
      {isVendorFormModalOpen && (
        <VendorForm
          operation={"Add Vendor"}
          isOpen={isVendorFormModalOpen}
          onClose={handleVendorFormCloseModal}
        />
      )}
    </>
  );
};

export default VendorHeader;
