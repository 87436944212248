import React, { useState } from "react";
import { Box, Button, VStack, Text, Heading, IconButton, Collapse } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, SettingsIcon, ViewIcon } from "@chakra-ui/icons";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { RiCarouselView } from "react-icons/ri";
import { GoGraph } from "react-icons/go";
import CarouselSetting from "./CarouselSetting";
import { IoMdNotificationsOutline } from "react-icons/io";
import Dashboard from "../DashboardTools/Dashboard";
import VendorBody from "../Vendor/VendorBody";
import { useAuth } from "../../../context/AuthContext";

const AdminDashboardContainer = () => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [activeSection, setActiveSection] = useState("dashboard");
    const [focusedButton, setFocusedButton] = useState("dashboard");
    const [isVendorVisible ,setIsVendorVisible] = useState(false);
    const {userData} = useAuth();
   

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);
    const toggleSection = (section) => setActiveSection(activeSection === section ? null : section);
    const handleButtonClick = (buttonId) => setFocusedButton(buttonId);
    const handleVendorClick = () => setIsVendorVisible((prev) => !prev);

    const renderContent = (isCollapsed) => {
        switch (focusedButton) {
            case "dashboard":
                return <Dashboard isCollapsed={isCollapsed} />;
            case "carousel":
                return <CarouselSetting />;
            case "notification":
                return <Text>Render Notification</Text>;
            default:
                return <Text>Select a subtopic to see the content.</Text>;
        }
    };

    return (
        <Box display="flex" height={`calc(100vh - 89px)`}>
            {/* Fixed Left-Side Drawer */}
            <Box
                width={isCollapsed ? "50px" : "200px"}
                transition="width 0.3s"
                position="fixed"
                height="100%"
                bg="#1F2937"
                color="white"
            >
                <VStack spacing={1} p={2}>
                    <Button
                        onClick={toggleCollapse}
                        colorScheme="teal"
                        aria-label="Toggle Sidebar"
                        p={1}
                        h={7}
                        minW={"auto"}
                        position={"relative"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        pl={2}
                        w={"100%"}
                        mb={2}
                    >
                        {!isCollapsed && "Admin Menu"}
                        {isCollapsed ? (
                            <FaAngleDoubleRight />
                        ) : (
                            <Box position={"absolute"} right={2}>
                                <FaAngleDoubleLeft />
                            </Box>
                        )}
                    </Button>
                    <Box w={"100%"}>
                        <Button
                            variant="ghost"
                            justifyContent={isCollapsed ? "center" : "flex-start"}
                            colorScheme="teal"
                            size={"sm"}
                            leftIcon={<GoGraph />}
                            w={"100%"}
                            pl={isCollapsed ? 5 : 3}
                            onClick={() => handleButtonClick("dashboard")}
                            bg={focusedButton === "dashboard" ? "teal.600" : "transparent"}
                            _hover={{
                                backgroundColor:
                                    focusedButton === "dashboard-sub1" ? "teal.700" : "teal.500",
                            }}
                        >
                            {!isCollapsed && "Dashboard"}
                        </Button>

                        {/* <Button
                            variant="ghost"
                            justifyContent={isCollapsed ? "center" : "flex-start"}
                            colorScheme="teal"
                            size={"sm"}
                            leftIcon={<ViewIcon />}
                            onClick={() => toggleSection("dashboard")}
                            w={"100%"}
                            pl={isCollapsed ? 5 : 3}
                        >
                            {!isCollapsed && "Dashboard"}
                        </Button>
                        <Collapse in={activeSection === "dashboard"} animateOpacity>
                            <VStack pl={isCollapsed ? 0 : 4} spacing={1} align="stretch">
                                <Button
                                    variant="ghost"
                                    justifyContent="flex-start"
                                    colorScheme="teal"
                                    size={"sm"}
                                    leftIcon={<ViewIcon />}
                                    pl={isCollapsed ? "10px" : 3}
                                >
                                    {!isCollapsed && "Subtopic 1"}
                                </Button>
                                <Button
                                    variant="ghost"
                                    justifyContent="flex-start"
                                    colorScheme="teal"
                                    size={"sm"}
                                    leftIcon={<ViewIcon />}
                                    pl={isCollapsed ? "10px" : 3}
                                >
                                    {!isCollapsed && "Subtopic 2"}
                                </Button>
                            </VStack>
                        </Collapse> */}
                    </Box>
                    <Box w={"100%"}>
                        <Button
                            variant="ghost"
                            justifyContent={isCollapsed ? "center" : "flex-start"}
                            colorScheme="teal"
                            size={"sm"}
                            leftIcon={<SettingsIcon />}
                            onClick={() => toggleSection("settings")}
                            w={"100%"}
                            pl={isCollapsed ? 5 : 3}
                        >
                            {!isCollapsed && "Settings"}
                        </Button>
                        <Collapse in={activeSection === "settings"} animateOpacity>
                            <VStack pl={isCollapsed ? 0 : 4} spacing={1} align="stretch" mt={1}>
                                <Button
                                    variant="ghost"
                                    justifyContent="flex-start"
                                    colorScheme="teal"
                                    size={"sm"}
                                    leftIcon={<RiCarouselView />}
                                    pl={isCollapsed ? "10px" : 3}
                                    onClick={() => handleButtonClick("carousel")}
                                    bg={focusedButton === "carousel" ? "teal.600" : "transparent"}
                                    _hover={{
                                        backgroundColor:
                                            focusedButton === "dashboard-sub1"
                                                ? "teal.700"
                                                : "teal.500",
                                    }}
                                >
                                    {!isCollapsed && "carousel"}
                                </Button>
                                <Button
                                    variant="ghost"
                                    justifyContent="flex-start"
                                    colorScheme="teal"
                                    size={"sm"}
                                    leftIcon={<IoMdNotificationsOutline />}
                                    pl={isCollapsed ? "10px" : 3}
                                    onClick={() => handleButtonClick("notification")}
                                    bg={
                                        focusedButton === "notification"
                                            ? "teal.600"
                                            : "transparent"
                                    }
                                    _hover={{
                                        backgroundColor:
                                            focusedButton === "dashboard-sub1"
                                                ? "teal.700"
                                                : "teal.500",
                                    }}
                                >
                                    {!isCollapsed && "Notification"}
                                </Button>
                            </VStack>
                        </Collapse>

                        {userData?.isSuperAdmin && (
                        <Button
                            variant="ghost"
                            justifyContent={isCollapsed ? "center" : "flex-start"}
                            colorScheme="teal"
                            size={"sm"}
                            leftIcon={<FaUserGroup />}
                            onClick={handleVendorClick}
                            w={"100%"}
                            pl={isCollapsed ? 5 : 3}
                        >
                            {!isCollapsed && "Vendor"}
                        </Button>
                        )}
                    </Box>
                </VStack>
            </Box>

            {/* Right-Side Content */}
            <Box
                flex="1"
                ml={isCollapsed ? "50px" : "200px"}
                transition="margin-left 0.3s"
                height="100%"
            >
                {!isVendorVisible && renderContent(isCollapsed)}
                {isVendorVisible && <VendorBody />}
            </Box>
        </Box>
    );
};

export default AdminDashboardContainer;
