import { createContext, useContext, useMemo, useState } from "react";
import { useAuth } from "./AuthContext";
import { useEnv } from "./EnvContext";
import axios from "axios";
import useGlobalToast from "../Utils/GlobalFunctions/toast";
import { useParams } from "react-router-dom";

const VendorContext = createContext();

export const VendorContextProvider = ({ children }) => {
  const toast = useGlobalToast();
  const { backendUrl } = useEnv();
  const [error, setError] = useState(null);
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const { tab: status } = useParams();

  const [isVendorFormOpen, setIsVendorFormOpen] = useState(false);
  const [operation, setOperation] = useState("Add Vendor");
  const [vendorData, setVendorData] = useState(null);
  const [allVendors, setAllVendors] = useState({
    all: [],
    active: [],
    blocked: [],
  });

  const openVendorForm = (operationType = "Add Vendor", data = null) => {
    setOperation(operationType);
    setVendorData(data);
    setIsVendorFormOpen(true);
  };

  const closeVendorForm = () => {
    setIsVendorFormOpen(false);
    setVendorData(null);
  };

  // Memoizing the allVendors state
  const memoizedAllVendors = useMemo(() => allVendors, [allVendors]);

  // Memoize filtered vendors based on status
  const filteredVendors = useMemo(() => {
    switch (status) {
      case "All":
        return memoizedAllVendors.all;
      case "ActiveVendors":
        return memoizedAllVendors.active;
      case "BlockedVendors":
        return memoizedAllVendors.blocked;
      default:
        return memoizedAllVendors.all;
    }
  }, [memoizedAllVendors, status]);

  // Fetch All vendors
  const fetchVendors = async (status, page, limit) => {
    setLoading(true);
    setError(null);
    let updateStatus;
    if (status === "") {
      updateStatus = "all";
    } else if (status === "true") {
      updateStatus = "blocked";
    } else {
      updateStatus = "active";
    }
    const token = userData?.token;
    console.log(status, page, limit, updateStatus);
    try {
      const response = await axios.get(`${backendUrl}/vendor`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass JWT token
        },
        params: {
          status,
          page,
          limit,
        },
      });
      const listVendor = response.data.data;

      setAllVendors((prevVendors) => {
        const updatedVendors = { ...prevVendors, [updateStatus]: listVendor };
        return updatedVendors;
      });

      console.log("Vendors fetched successfully", response.data.data);
      return response.data.data;
    } catch (err) {
      console.log("Error in fetching vendors", err);
      setError(err.response?.data?.message || "Failed to fetch vendors");
    } finally {
      setLoading(false);
    }
  };

  // Add vendor
  const addVendor = async (newVendorData) => {
    setLoading(true);
    setError(null);
    try {
      const token = userData?.token; // Assuming token is stored in vendorData
      const response = await axios.post(
        `${backendUrl}/vendor/add`,
        newVendorData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const addVendorRes = response.data;
      const addedVendor = addVendorRes.vendor;

      setAllVendors((prevVendors) => {
        const updatedVendors = {
          ...prevVendors,
          all: [...prevVendors.all, addedVendor], // Add to 'all' list
          active: [...prevVendors.active, addedVendor], // Add to 'active' list
        };
        return updatedVendors;
      });

      toast.success("New Vendor Added Successfully!");
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Update vendor
  const updateVendor = async (updatedVendorData) => {
    setLoading(true);
    setError(null);
    console.log(updatedVendorData);

    try {
      const token = userData?.token;
      const response = await axios.put(
        `${backendUrl}/vendor/${updatedVendorData._id}`,
        updatedVendorData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response:", response.data);

      const updatedVendor = response.data.data;

      setAllVendors((prevVendors) => {
        // Update the all array
        const updatedAll = prevVendors.all.map((vendor) =>
          vendor._id === updatedVendor._id
            ? { ...vendor, ...updatedVendor }
            : vendor
        );

        // Update the active array if the vendor is active
        const updatedActive = prevVendors.active.map((vendor) =>
          vendor._id === updatedVendor._id
            ? { ...vendor, ...updatedVendor }
            : vendor
        );

        // Return the updated state for all and active arrays, keeping blocked unchanged
        return {
          ...prevVendors,
          all: updatedAll,
          active: updatedActive,
        };
      });

      toast.success("Vendor Updated Successfully!");
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Toggle block vendor status
  const toggleVendorStatus = async (vendorId) => {
    if (!userData?.isSuperAdmin) {
      toast.error("User is unauthorized !!");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = userData?.token;
      if (!token) {
        throw new Error("Authorization token is missing.");
      }

      const response = await axios.patch(
        `${backendUrl}/vendor/${vendorId}/block`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const updatedVendorId = response.data.data._id;

      setAllVendors((prevVendors) => {
        // Update the 'all' array
        const updatedAll = prevVendors.all.map((vendor) =>
          vendor._id === updatedVendorId
            ? { ...vendor, isBlocked: !vendor.isBlocked }
            : vendor
        );

        // Update the 'active' array (only if vendor is active and not blocked)
        const updatedActive = prevVendors.active.map((vendor) =>
          vendor._id === updatedVendorId
            ? { ...vendor, isBlocked: !vendor.isBlocked }
            : vendor
        );

        return {
          ...prevVendors,
          all: updatedAll,
          active: updatedActive,
        };
      });

      toast.success("Vendor status updated successfully!");
    } catch (err) {
      console.error("Error occurred:", err);
      const errorMessage =
        err.response?.data?.message || err.message || "Something went wrong";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Delete vendor
  const deleteVendor = async (vendorId) => {
    if (!userData?.isSuperAdmin) {
      toast.error("User is unauthorized !!");
      console.log("Unauthorized access attempt");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = userData?.token;
      if (!token) {
        throw new Error("Authorization token is missing.");
      }

      const response = await axios.delete(`${backendUrl}/vendor/${vendorId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the JWT token in the header
        },
      });

      console.log("Vendor deleted successfully:", response.data.data._id);

      // Remove the vendor from 'all' and 'active' arrays
      setAllVendors((prevVendors) => {
        const updatedAll = prevVendors.all.filter(
          (vendor) => vendor._id !== vendorId
        );

        const updatedActive = prevVendors.active.filter(
          (vendor) => vendor._id !== vendorId
        );

        return {
          ...prevVendors,
          all: updatedAll,
          active: updatedActive,
        };
      });

      toast.success("Vendor deleted successfully!");
    } catch (err) {
      console.log("Error", err);
      let error = err.response
        ? err.response?.data?.message
        : err.message
        ? err.message
        : "Something Went Wrong";
      setError(error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    allVendors: memoizedAllVendors,
    filteredVendors,
    error,
    loading,
    isVendorFormOpen,
    openVendorForm,
    closeVendorForm,
    operation,
    vendorData,
    fetchVendors,
    addVendor,
    updateVendor,
    toggleVendorStatus,
    deleteVendor,
  };

  return (
    <VendorContext.Provider value={value}>{children}</VendorContext.Provider>
  );
};

export const useVendor = () => useContext(VendorContext);
