import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { FaLock, FaUnlock } from "react-icons/fa";
import VendorDetails from "./VendorDetails";
import { useVendor } from "../../../context/VendorContext";
import VendorForm from "./VendorForm";

const VendorContainer = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateFormModalOpen, setisUpdateFormModalOpen] = useState(false);
  const [selectedVendorUpdate, setSelectedVendorUpdate] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const { toggleVendorStatus, deleteVendor } = useVendor();
  const [vendors, setVendors] = useState(data);

  useEffect(() => {
    setVendors(data); // Updating vendor list if data changes
  }, [data]);

  const handleViewDetails = (vendor) => {
    console.log("Viewing details of vendor", vendor);
    setSelectedVendor(vendor);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseUpdateFormModal = () => {
    setisUpdateFormModalOpen(false); // Close the update form modal
    setSelectedVendorUpdate(null); // Clear the selected vendor update data
  };

  const onUpdate = (vendor) => {
    setisUpdateFormModalOpen(true);
    setSelectedVendorUpdate(vendor);
  };

  const handleStatusToggle = async (vendor) => {
    await toggleVendorStatus(vendor._id); // Using vendor._id to toggle status
    // Optionally, you can also update the local state to reflect the change immediately
    setVendors((prevVendors) =>
      prevVendors.map((v) =>
        v._id === vendor._id
          ? { ...v, status: v.status === "blocked" ? "active" : "blocked" }
          : v
      )
    );
  };

  const handleDeleteVendor = async (vendorId) => {
    await deleteVendor(vendorId); // Deleting vendor by vendorId
    setVendors((prevVendors) =>
      prevVendors.filter((vendor) => vendor._id !== vendorId)
    ); // Updating the state to remove deleted vendor
  };

  return (
    <Box overflow="auto" maxH="calc(100vh - 16px)" p={4}>
      <Table variant="solid" colorScheme="gray" size="sm">
        <Thead>
          <Tr bg="gray.800" p={4}>
            <Th>Company Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Address</Th>
            <Th>BD Limit</Th>
            <Th>Recruiter Limit</Th>
            <Th>Manager Limit</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {vendors.length > 0 &&
            vendors.map((vendor) => (
              <Tr key={vendor._id}>
                <Td>{vendor.companyName}</Td>
                <Td>{vendor.email}</Td>
                <Td>{vendor.phone}</Td>
                <Td>
                  {vendor.companyAddress?.city}, {vendor.companyAddress?.state},{" "}
                  {vendor.companyAddress?.country}
                </Td>
                <Td align="center" isNumeric>
                  {vendor?.user_limits?.bd || "N/A"}
                </Td>
                <Td align="center" isNumeric>
                  {vendor?.user_limits?.recruiter || "N/A"}
                </Td>
                <Td align="center" isNumeric>
                  {vendor?.user_limits?.manager || "N/A"}
                </Td>
                <Td>
                  <Tooltip label={vendor.isBlocked ? "Blocked" : "Unblocked"}>
                    {vendor.isBlocked ? (
                      <FaLock color="red" />
                    ) : (
                      <FaUnlock color="green" />
                    )}
                  </Tooltip>
                </Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<FiMoreVertical />}
                      variant="ghost"
                      aria-label="Options"
                    />
                    <MenuList>
                      <MenuItem onClick={() => handleViewDetails(vendor)}>
                        View Details
                      </MenuItem>
                      <MenuItem
                        color="yellowGreen"
                        onClick={() => onUpdate(vendor)}
                      >
                        Update Vendor
                      </MenuItem>
                      <MenuItem
                        color="yellow.500"
                        onClick={() => handleStatusToggle(vendor)}
                      >
                        {vendor.isBlocked ? "Unblock Vendor" : "Block Vendor"}
                          
                      </MenuItem>
                      <MenuItem
                        color="red"
                        onClick={() => handleDeleteVendor(vendor._id)}
                      >
                        Delete Vendor
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      {/* Vendor Details Modal */}
      {isModalOpen && (
        <VendorDetails
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          vendor={selectedVendor}
        />
      )}
      {isUpdateFormModalOpen && (
        <VendorForm
          operation={"Update Vendor"}
          isOpen={isUpdateFormModalOpen}
          onClose={handleCloseUpdateFormModal}
          initialData={selectedVendorUpdate}
        />
      )}
    </Box>
  );
};

export default VendorContainer;
