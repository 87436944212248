import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useVendor } from "../../../context/VendorContext";
import VendorContainer from "./VendorContainer";
import { tagColor } from "../../../Utils/GlobalFunctions/TagColor"; // You may need to adapt this
import VendorHeader from "./VendorHeader";

const VendorBody = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { tab: status } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const initialPath = pathname.split("/")[1];

  const { fetchVendors, allVendors } = useVendor();

  const tabConfig = [
    { status: "All", key: "", color: "" },
    { status: "ActiveVendors", key: "false", color: "green" },
    { status: "BlockedVendors", key: "true", color: "blue" },
  ];

  // Fetch vendors when status changes
  useEffect(() => {
    const currentTab =
      tabConfig.find((tab) => tab.status === status) || tabConfig[0];
    fetchVendors(currentTab.key, 1, 30);
    setTabIndex(tabConfig.indexOf(currentTab));
  }, [status]);

  // Dynamically update the vendor list based on fetched vendors and status
  const getVendorsByStatus = () => {
    if (!allVendors) return [];
    switch (status) {
      case "All":
        return allVendors.all || [];
      case "ActiveVendors":
        return allVendors.active || [];
      case "BlockedVendors":
        return allVendors.blocked || [];
      default:
        return allVendors.all || [];
    }
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
    const tabMapping = ["All", "ActiveVendors", "BlockedVendors"];
    navigate(`/${initialPath}/${tabMapping[index]}/Default`);
  };

 

  return (
    <div
      style={{
        background: "linear-gradient(180deg, black, rgb(29, 48, 75))",
        height: "calc(100vh - 3rem)",
      }}
    >
      <Tabs
        isLazy
        variant="enclosed"
        colorScheme="green"
        color={"white"}
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <VendorHeader/>
        <TabList mb="1" style={{ background: "black" }}>
          {tabConfig.map((tab) => (
            <Tab key={tab.key}>
              <Text
                bg={tagColor(tab.color).bg}
                color={tagColor(tab.color).clr}
                px={2}
                borderRadius={20}
                fontWeight="bold"
                fontSize="sm"
              >
                {tab.status}
              </Text>
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabConfig.map((tab) => (
            <TabPanel
              key={tab.key}
              pb={0}
              style={{
                height: "calc(100vh - 5rem)",
                overflow: "clip",
                paddingTop: 0,
              }}
              pl={0}
              pr={0}
            >
              <VendorContainer data={getVendorsByStatus()} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default VendorBody;
