import {  Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import ClientForm from "./ClientForm";

const ClientHeader = ({ filterData, initialPath }) => {
    const [inputValue, setInputValue] = useState("");
    const [isClientFormModalOpen, setIsClientFormModalOpen] = useState(false);


    const handleSearchInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            // console.log("enter clicked, data to be filtered: ", inputValue);
            filterData(inputValue);
        }
    };

    const handleSearchClick = () => {
        // console.log("search btn clicked, data to be filtered: ", inputValue);
        filterData(inputValue);
    };

    const handleClientForm = () => {
        setIsClientFormModalOpen(true);
    };

    const handleClientFormCloseModal = () => {
        setIsClientFormModalOpen(false);
    };
    return (
        <>
            <Flex
                direction={"row-reverse"}
                alignItems={"center"}
                position={"absolute"}
                top={"1px"}
                right="0"
            >
                {/* <Text fontSize="2xl">Allocated Client List</Text> */}
                {/* <Box>
                    <InputGroup>
                        <Input
                            placeholder="Search..."
                            style={{
                                background: "white",
                                color: "black",
                                padding: "0",
                                heigth: "0px",
                                borderRadius: "20px",
                                paddingLeft: "10px",
                                paddingRight: "32px",
                            }}
                            variant="unstyled"
                            onMouseEnter={(e) => {
                                e.target.style.background = "#cfcfcf";
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.background = "white";
                            }}
                            onChange={handleSearchInputChange}
                            onKeyDown={handleKeyDown}
                            value={inputValue}
                        />
                        <InputRightElement
                            style={{
                                color: "black",
                                heigth: "0px",
                                padding: "0",
                                top: "-8px",
                            }}
                            onClick={handleSearchClick}
                        >
                            <FaSearch style={{ cursor: "pointer" }} />
                        </InputRightElement>
                    </InputGroup>
                </Box>
                <Button
                    colorScheme="gray"
                    size="xs"
                    style={{
                        borderRadius: "20px",
                        background: "#1F2937",
                        color: "white",
                        marginInline: "10px",
                        transition: "background 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.background = "#3B4A5A";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.background = "#1F2937";
                    }}
                >
                    Filter Job Descriptions
                </Button> */}
                 {initialPath &&
                          (initialPath === "admin_dashboard" ||
                            initialPath === "bd_dashboard") && (

                                <Button
                                colorScheme="gray"
                                size="xs"
                    style={{
                        borderRadius: "20px",
                        background: "#1F2937",
                        color: "white",
                        marginInline: "10px",
                        transition: "background 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.background = "#3B4A5A";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.background = "#1F2937";
                    }}
                    onClick={() => {
                        handleClientForm();
                    }}
                >
                    Add New Client
                </Button>
                    )}
            </Flex>
            {isClientFormModalOpen && (
                <ClientForm
                    operation={"Add Client"}
                    isOpen={isClientFormModalOpen}
                    onClose={handleClientFormCloseModal}
                />
            )}
        </>
    );
};

export default ClientHeader;
